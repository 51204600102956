import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogueGrid = _resolveComponent("CatalogueGrid")!
  const _component_CatalogueLocalGrid = _resolveComponent("CatalogueLocalGrid")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.parentModel.id != null)
      ? (_openBlock(), _createBlock(_component_CatalogueGrid, {
          key: 0,
          parentModel: _ctx.parentModel,
          controller: _ctx.controller,
          columns: _ctx.columns,
          filterRowVisible: _ctx.filterRowVisible,
          groupPanelVisible: _ctx.groupPanelVisible,
          headerFilterVisible: _ctx.headerFilterVisible,
          filterPanelVisible: _ctx.filterPanelVisible,
          showPageSizeSelector: true,
          pageSize: 10,
          pageSizes: _ctx.pageSizes,
          editingConfig: _ctx.editingConfig,
          selectionConfig: _ctx.selectionConfig,
          designConfig: _ctx.designConfig,
          popupCreateEditComponent: _ctx.popupCreateEditComponent,
          pushDataToSelectControl: _ctx.pushDataToSelectControl
        }, null, 8, ["parentModel", "controller", "columns", "filterRowVisible", "groupPanelVisible", "headerFilterVisible", "filterPanelVisible", "pageSizes", "editingConfig", "selectionConfig", "designConfig", "popupCreateEditComponent", "pushDataToSelectControl"]))
      : (_openBlock(), _createBlock(_component_CatalogueLocalGrid, {
          key: 1,
          parentModel: _ctx.parentModel,
          controller: _ctx.controller,
          columns: _ctx.columns,
          filterRowVisible: _ctx.filterRowVisible,
          groupPanelVisible: _ctx.groupPanelVisible,
          headerFilterVisible: _ctx.headerFilterVisible,
          filterPanelVisible: _ctx.filterPanelVisible,
          showPageSizeSelector: true,
          pageSize: 10,
          pageSizes: _ctx.pageSizes,
          editingConfig: _ctx.editingConfig,
          selectionConfig: _ctx.selectionConfig,
          designConfig: _ctx.designConfig,
          customDataSource: _ctx.parentModel[_ctx.fieldName],
          popupCreateEditComponent: _ctx.popupCreateEditComponent,
          pushDataToSelectControl: _ctx.pushDataToSelectControl
        }, null, 8, ["parentModel", "controller", "columns", "filterRowVisible", "groupPanelVisible", "headerFilterVisible", "filterPanelVisible", "pageSizes", "editingConfig", "selectionConfig", "designConfig", "customDataSource", "popupCreateEditComponent", "pushDataToSelectControl"]))
  ]))
}