
import { defineComponent } from "vue";
import CatalogueGrid from "./CatalogueGrid.vue";
import CatalogueLocalGrid from "./CatalogueLocalGrid.vue";

export default defineComponent({
  components: {
    CatalogueGrid,
    CatalogueLocalGrid,
  },
  props: {
    parentModel: { required: true },
    controller: { required: true },
    columns: { required: true },
    popupCreateEditComponent: { required: false },
    editingConfig: { required: true },
    selectionConfig: { required: true },
    designConfig: { required: true },
    fieldName: { required: false, default: "items" },
    filterRowVisible: { required: false, default: false },
    groupPanelVisible: { required: false, default: false },
    headerFilterVisible: { required: false, default: false },
    filterPanelVisible: { required: false, default: false },
    pushDataToSelectControl: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      pageSizes: [10, 20, 50, 100],
    };
  },
});
